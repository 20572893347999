export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const SIGN_IN_ADMIN = '/signin/admin';
export const DASHBOARD = '/dashboard';
export const CLIENT_PORFILE ='/clientprofile';
export const TRANSACTION_LIST = '/transactionlist';
export const INVALID = "/invalid";
export var FUNCTIONAL_BASE_URL;
if (process.env.REACT_APP_FIREBASE_CONFIG === "dev") {
  FUNCTIONAL_BASE_URL = "https://asia-east2-dip-dev-882b0.cloudfunctions.net/";
} else if (process.env.REACT_APP_FIREBASE_CONFIG === "prod") {
  FUNCTIONAL_BASE_URL = "https://asia-east2-dip-nonprod.cloudfunctions.net/";
} else {
  FUNCTIONAL_BASE_URL = "https://asia-southeast2-rampver.cloudfunctions.net/";
}